@tailwind base;

/* Write your own custom base styles here */
/* Firefox */
* {
  scrollbar-width: 20px;
  scrollbar-color: rgba(155, 155, 155, 0.5);
  background: transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
/* Firefox */
.scrollbar-visible {
  scrollbar-width: 20px;
  scrollbar-color: rgba(155, 155, 155, 0.5);
  background: #000000;
}
/* Chrome, Edge, and Safari */
.scrollbar-visible::-webkit-scrollbar {
  width: 9px;
  background-color: #000000;
}

.scrollbar-visible::-webkit-scrollbar-track {
  background: #000000;
  border-radius: 5px;
}

.scrollbar-visible::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: #000000;
}

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write your own custom component styles here */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */

/* RESIZE CLASSES for react-resize */

input,
button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

input[type="text"],
input[type="button"],
div[type="button"] {
  -webkit-appearance: none !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
}

.helptips p {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.helptips a {
  text-decoration: underline !important;
}

.helptips a:hover {
  text-decoration: none !important;
}

.helptips strong {
  margin-top: 10px !important;
}

.helptips hr {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.helptips ol li,
.helptips ul li {
  list-style-type: circle !important;
  padding-left: 10px !important;
  margin-left: 10px !important;
}

.reacttooltip {
  pointer-events: auto !important;
  opacity: 1 !important;
}
.reacttooltip:hover {
  visibility: visible !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
  background: red;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*REACT HORIZONTAL MENU SCROLLBAR*/
/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*menu shadow*/
.shadow-inner-r-white {
  -webkit-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
}

.shadow-inner-r-dark {
  -webkit-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 1);
  -moz-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 1);
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 1);
}
.shadow-l-dark {
  -webkit-box-shadow: -7px 0 9px -7px rgba(0, 0, 0, 1);
  -moz-box-shadow: -7px 0 9px -7px rgba(0, 0, 0, 1);
  box-shadow: -7px 0 9px -7px rgba(0, 0, 0, 1);
}

/*markdown*/
.markdown {
  @apply text-sm;
}

.markdown ul li {
  @apply list-disc;
  @apply ml-5;
}

.markdown ol li {
  @apply list-decimal;
  @apply ml-5;
  @apply mb-2;
}

.markdown ul, .markdown ol {
  @apply mb-3;
}

.markdown table td,
.markdown table th {
  @apply border;
  @apply p-1;
}
.markdown table th {
  @apply font-bold;
}

.markdown h1 {
  @apply text-2xl; 
  @apply font-bold;
  @apply mt-2;
}

.markdown h2 {
  @apply text-lg; 
  @apply font-bold;
  @apply mt-2;
}

.markdown h3 {
  @apply text-sm; 
  @apply font-bold;
  @apply mt-2;
}

.markdown h4 {
  @apply text-sm; 
  @apply font-bold;
  @apply mt-2;
}

.markdown a {
  @apply text-blue-400;
}

.markdown a:hover {
  @apply underline;
}

.markdown p img, .markdown div img {
  height: 200;
}

.markdown p {
  margin-bottom: 10px;
}

/*popup arrows*/
.popup-content {
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
}
html[data-theme="dark"] .popup-content {
  background-color: rgb(41, 45, 62);
  color: #fff;
  border: 1px solid #9a9595;
}
html[data-theme="light"] .popup-content {
  background-color: #fff;
  color: #000;
  border: 1px solid #d7d7d7;
}
html[data-theme="dark"] .popup-arrow > svg {
  color: rgb(41, 45, 62);
  stroke-width: 2px;
  stroke: #9a9595;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
}
html[data-theme="light"] .popup-arrow > svg {
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
}
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

/*drawing canvas*/
div.ignore-scroll {
  touch-action: none;
  -ms-touch-action: none;
  -touch-action: none;
  -webkit-overflow-scrolling: touch;
}

#cont {
  position: relative;
  width: 500px;
  height: 500px;
}

canvas#cursor {
  border: 1px solid;
}

#cont#cursor,
.canvas-container {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

#cursor {
  pointer-events: none !important;
}

/* TENOR GIF SEARCH */
.gpr-header {
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: #000000;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

/* MDX EDITOR */
/* .mdxeditor a {
  @apply text-blue-400;
  @apply underline;
  @apply cursor-pointer;
}
.mdxeditor a:hover {
  @apply no-underline;
} */

/*
.mdxeditor h1,.mdxeditor h2,.mdxeditor h3,.mdxeditor h4,.mdxeditor h5,.mdxeditor h6 {
  @apply py-5;
  @apply font-bold;
}

.mdxeditor p {
  @apply pb-5;
}

.mdxeditor h1 {
  font-size: 32pt;
}

.mdxeditor h2 {
  font-size: 28pt;
}

.mdxeditor h3 {
  font-size: 24pt;
}

.mdxeditor h4 {
  font-size: 20pt;
}

.mdxeditor h5 {
  font-size: 16pt;
}

.mdxeditor h6 {
  font-size: 12pt;
}

.mdxeditor {
  @apply text-sm;
}

.mdxeditor ul li {
  @apply list-disc;
  @apply ml-5;
}

.mdxeditor ul li[role=checkbox] {
  @apply list-none;
  @apply ml-0;
}



.mdxeditor ol li {
  @apply list-decimal;
  @apply ml-5;
}

*/

.prose {
  @apply text-white;
}

.prose li,
.prose p {
  @apply text-white;
}

.prose ul li[role="checkbox"] {
  @apply list-none;
  @apply my-0;
  @apply text-sm;
}

.prose img {
  @apply bg-gray-500;
}

.prose img {
  @apply my-0;
}
